import React from "react";
import RichText from "components/atoms/RichText/RichText";
import Btn from "components/atoms/Btn/Btn";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const PresentationBiography = ({ content }) => {
  const { routes } = React.useContext(LayoutContext);
  const url = resolveUrl(content.primary.btn_link, routes);
  return (
    <div className="presentation">
      <div className="presentation__header">
        <h1 className="presentation__header__title">
          <RichText type="text" content={content.primary.main_title} />
          <span className="presentation__header__subtitle">
            <RichText type="text" content={content.primary.main_subtitle} />
          </span>
        </h1>
      </div>
      <div className="presentation__content">
        <div className="presentation__content__inner">
          <div className="presentation__content__title">
            <RichText type="text" content={content.primary.title} />
          </div>
          <div className="presentation__content__subtitle">
            <RichText content={content.primary.subtitle} />
          </div>
          <div className="presentation__content__text">
            <RichText content={content.primary.text} />
          </div>
          <div className="presentation__content__button">
            <Btn to={url} appearance="secondary btn-secondary-light">
              <RichText type="text" content={content.primary.btn_label} />
            </Btn>
          </div>
        </div>
        <div className="presentation__content__bg">
          <div className="presentation__content__bg__left"></div>
          <div className="presentation__content__bg__right"></div>
        </div>
      </div>
      {content.primary.image.url ? (
        <div className="presentation__bg">
          <div className="presentation__bg__image">
            <picture>
              <img
                src={content.primary.image.url}
                alt="Photographe Pascale Pyot"
              />
            </picture>
          </div>
        </div>
      ) : null}

      {/* <div className="presentation__bg">
        <div className="presentation__bg__image">
          <picture>
            <img
              src={content.primary.image.url}
              alt="Photographe Pascale Pyot"
            />
          </picture>
        </div>
        <div className="presentation__bg__color">
          <div className="presentation__bg__color__inner"></div>
        </div>
      </div>
      <div className="presentation__inner">
        <div className="presentation__inner__main">
          <h1 className="presentation__inner__main__title">
            <RichText type="text" content={content.primary.main_title} />
            <span className="presentation__inner__main__subtitle">
              <RichText type="text" content={content.primary.main_subtitle} />
            </span>
          </h1>
        </div>
        <div className="presentation__inner__content">
          <div className="presentation__inner__content__container">
            <div className="padded">
              <div className="presentation__inner__content__title">
                <RichText type="text" content={content.primary.title} />
              </div>
              <div className="presentation__inner__content__subtitle">
                <RichText type="text" content={content.primary.subtitle} />
              </div>
              <div className="presentation__inner__content__text">
                <RichText type="text" content={content.primary.text} />
              </div>

              <div className="presentation__inner__content__btn">
                <Btn
                  to={content.primary.btn_link.uid}
                  appearance="secondary btn-secondary-light"
                >
                  <RichText type="text" content={content.primary.btn_label} />
                </Btn>
              </div>
            </div>
            <div className="presentation__inner__content__container__bg">
              <div className="presentation__inner__content__container__bg__left"></div>
              <div className="presentation__inner__content__container__bg__right"></div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PresentationBiography;
